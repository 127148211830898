/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
}
.h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}
.h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}
.h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}
.h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}
.h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.buttons {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.main-text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.caption {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.table-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.helptext {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.small {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}
.mobile-h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  font-size: 28px;
  line-height: 36px;
}
.mobile-h3 {
  font-family: 'Montserrat', sans-serif;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}
.mobile-h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}
.ooc-input-v2.ant-input {
  height: 40px;
  padding: 9px 15px;
  border: 1px solid #E4E7F2;
  border-radius: 4px;
  font-family: 'IBM Plex sans', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.ooc-input-v2.ant-input-number {
  height: 40px;
  width: auto;
  border: 1px solid #E4E7F2;
  border-radius: 4px;
}
.ooc-input-v2.ant-input-number .ant-input-number-input-wrap {
  height: 100%;
}
.ooc-input-v2.ant-input-number .ant-input-number-input {
  height: 100%;
  padding: 9px 15px;
  font-family: 'IBM Plex sans', sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.ooc-input-v2.ooc-input-v2__form-input .ant-form-item-label label {
  color: #6f7c98;
}
.ooc-input-v2.ooc-input-v2__form-input .ant-input {
  border: 1px solid #e4e7f2;
  border-radius: 4px;
}
.ant-input.ooc-input-v2.ooc-input-v2__textarea {
  padding: 7px 15px;
}
.ooc-input-v2__textarea > textarea {
  padding: 8px 16px;
}
.ooc-input-v2__textarea > textarea.ant-input-borderless {
  padding: 0;
  height: fit-content;
  overflow: hidden;
  resize: none;
}
.ooc-input-v2.ooc-input-v2__search {
  display: flex;
  align-items: center;
  height: 40px;
  position: relative;
}
.ooc-input-v2.ooc-input-v2__search .ant-input {
  padding: 11px 63px 11px 16px;
  line-height: 16px;
}
.ooc-input-v2.ooc-input-v2__search .anticon {
  font-size: 24px;
  cursor: pointer;
  color: #909EBB;
}
.ooc-input-v2.ooc-input-v2__search .anticon:hover {
  color: #0071CE;
}
.ooc-input-v2.ooc-input-v2__search .ooc-input-v2__clear-icon {
  position: absolute;
  right: 40px;
}
.ooc-input-v2.ooc-input-v2__search .ooc-input-v2__search-icon {
  position: absolute;
  right: 14px;
}
