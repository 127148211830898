/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
}
.h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}
.h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}
.h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}
.h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}
.h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.buttons {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.main-text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.caption {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.table-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.helptext {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.small {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}
.mobile-h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  font-size: 28px;
  line-height: 36px;
}
.mobile-h3 {
  font-family: 'Montserrat', sans-serif;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}
.mobile-h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}
.ooc-button-v2.ant-btn {
  height: 44px;
  border-radius: 4px;
  font-family: 'IBM Plex sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 23px;
  padding-right: 23px;
  white-space: normal;
  text-align: left;
  z-index: 1040;
}
.ooc-button-v2.ant-btn.m-20 {
  margin-top: 20px;
}
.ooc-button-v2.ant-btn .anticon {
  line-height: 0;
  font-size: 15px;
}
.ooc-button-v2.ant-btn .anticon-plus {
  padding: 0.167em;
}
.ooc-button-v2.ant-btn-primary {
  background: linear-gradient(94.25deg, #007be0 -0.43%, #0058a0 54.69%);
  border-color: #0071CE;
  color: white;
}
.ooc-button-v2.ant-btn-primary:hover {
  background: #0071CE;
  transition: none;
}
.ooc-button-v2.ant-btn.form-next-btn {
  padding-left: 24px;
  padding-right: 24px;
}
.ooc-button-v2.ant-btn.download-btn {
  background: #FFFFFF;
  border: 1px solid #0071CE;
  color: #0071CE;
  text-shadow: none;
  box-shadow: none;
}
.ooc-button-v2.ant-btn.download-btn svg {
  height: 100%;
  margin-right: 8px;
}
.ooc-button-v2.ant-btn.download-btn:hover {
  border-color: #40a9ff;
  color: #40a9ff;
}
.ooc-button-v2.ant-btn-ghost {
  border-color: #0071CE;
  color: #0071CE;
}
.ooc-button-v2.ant-btn-link {
  display: flex;
  height: fit-content;
  padding: 0;
  color: #0071CE;
  border: 0;
  text-align: left;
}
.ooc-button-v2.ant-btn-link.icon-on-right {
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-between;
}
.ooc-button-v2.ant-btn-link.icon-on-right span:not([role="img"]) {
  margin-left: 0;
  margin-right: 9px;
}
.ooc-button-v2.ant-btn-link.icon-on-right span[role="img"] {
  padding: 0.167em;
}
.ooc-button-v2.ant-btn-link.icon-button {
  display: flex;
  height: fit-content;
  padding: 0;
  border: 0;
  color: #909EBB;
  transition: all 0.3s;
}
.ooc-button-v2.ant-btn-link.icon-button:hover,
.ooc-button-v2.ant-btn-link.icon-button:focus {
  color: #005EAC;
}
.ooc-button-v2.ant-btn-link.icon-button .width {
  width: 16px;
}
.ooc-button-v2.ant-btn-link.icon-button-disabled,
.ooc-button-v2.ant-btn-link.icon-button-disabled:hover,
.ooc-button-v2.ant-btn-link.icon-button-disabled:focus {
  color: #BDBDBD;
}
.ooc-button-v2.ant-btn__download {
  height: initial;
  width: initial;
  padding: initial;
  border: initial;
  background: initial;
  font-size: 18px;
}
.ooc-button-v2.ant-btn__download:focus {
  color: #333;
  background: #F5F6F8;
  border-color: #F5F6F8;
}
.ooc-button-v2.ant-btn__download:hover {
  color: #333;
  background: #F5F6F8;
  border-color: #F5F6F8;
}
.ooc-button-v2--checkbox.ant-checkbox-wrapper {
  font-family: 'IBM Plex sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #12152A;
}
.ooc-button-v2--checkbox.ant-checkbox-wrapper .ant-checkbox-inner {
  border-radius: 2px;
  border: 1px solid #E4E7F2;
  background-color: white;
}
.ooc-button-v2--checkbox.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0071CE;
}
