/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
}
.h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}
.h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}
.h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}
.h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}
.h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.buttons {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.main-text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.caption {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.table-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.helptext {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.small {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}
.mobile-h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  font-size: 28px;
  line-height: 36px;
}
.mobile-h3 {
  font-family: 'Montserrat', sans-serif;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}
.mobile-h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}
.my-educational-programs .header {
  width: 850px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  justify-content: space-between;
}
.my-educational-programs .header .title {
  margin: 0;
}
.my-educational-programs .search {
  max-width: 850px;
  margin-bottom: 24px;
}
.my-educational-programs .search .search-icon {
  width: 16px;
  height: 16px;
}
.my-educational-programs .search .search-icon path {
  fill: #909EBB;
}
.my-educational-programs .programs-list {
  max-width: 851px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.my-educational-programs .programs-list .list-item {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
}
.my-educational-programs .programs-list .list-item:not(:last-child) {
  border-bottom: 1px solid #E4E7F2;
}
.my-educational-programs .programs-list .list-item .item-status {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #3B4168;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.my-educational-programs .programs-list .list-item .item-status .ant-checkbox + span {
  color: #6F7C98;
}
.my-educational-programs .programs-list .list-item .item-status.check {
  justify-content: space-between;
}
.my-educational-programs .programs-list .list-item .item-status .status-condition {
  display: flex;
  align-items: center;
}
.my-educational-programs .programs-list .list-item .item-status .item-mode-right {
  border-top-left-radius: 5px!important;
  border-bottom-left-radius: 5px!important;
  margin: 0!important;
  padding: 0 4px!important;
  font-size: 14px;
}
.my-educational-programs .programs-list .list-item .item-status .item-mode-left {
  border-top-right-radius: 5px!important;
  border-bottom-right-radius: 5px!important;
  margin: 0!important;
  padding: 0 4px!important;
  color: #909EBB;
}
.my-educational-programs .programs-list .list-item .item-status .circle {
  width: 8px;
  height: 8px;
  border-radius: 16px;
  background: #73AAFF;
  margin-right: 6px;
}
.my-educational-programs .programs-list .list-item .item-status .circle.draft {
  background: #F7B055;
}
.my-educational-programs .programs-list .list-item .item-content {
  display: flex;
  justify-content: space-between;
}
.my-educational-programs .programs-list .list-item .item-content .content-info h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #3B4168;
  margin-bottom: 12px;
}
.my-educational-programs .programs-list .list-item .item-content .content-info h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.my-educational-programs .programs-list .list-item .item-content .content-info .list-footer {
  display: flex;
  justify-content: space-between;
  width: 110%;
}
.my-educational-programs .programs-list .list-item .item-content .content-info .list-footer .unsend {
  margin-left: 212px;
}
.my-educational-programs .programs-list .list-item .item-content .content-info p {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #6F7C98;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.my-educational-programs .programs-list .list-item .item-content .content-info .send-btn.block {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0071CE;
  display: flex;
  align-items: center;
}
.my-educational-programs .programs-list .list-item .item-content .content-info .send-btn.block .btn-icon {
  margin-left: 5px;
}
.my-educational-programs .programs-list .list-item .item-content .content-info .send-btn.none {
  display: none;
}
.my-educational-programs .programs-list .list-item .item-content .content-actions {
  display: flex;
  justify-content: space-between;
}
.my-educational-programs .programs-list .list-item .item-content .content-actions svg {
  width: 16px;
  cursor: pointer;
}
.my-educational-programs .programs-list .list-item .item-content .content-actions svg:not(:last-child) {
  margin-right: 45px;
}
.my-educational-programs .programs-list .list-item.hover .content-info h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #3B4168;
  margin-bottom: 12px;
  cursor: pointer;
}
.my-educational-programs .programs-list .list-item.hover .content-info h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.my-educational-programs .programs-list .list-item.hover .content-info h5:hover {
  color: #0071CE;
}
.my-educational-programs .programs-list .list-item.hover:hover {
  background-color: #F5FBFF;
  cursor: pointer;
  transition: 0.22s;
}
.my-educational-programs .ant-input-affix-wrapper {
  border-radius: 4px;
  border-color: #E4E7F2;
}
.main-opop-modal .plug-tab {
  text-align: center;
}
.main-opop-modal .plug-tab p {
  color: #3B4168;
  font-size: 16px;
}
.main-opop-modal h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #3B4168;
  margin-bottom: 0;
}
.main-opop-modal h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.main-opop-modal .subtitle {
  color: #6F7C98;
  font-size: 14px;
}
.main-opop-modal .ant-list-header {
  padding: 0!important;
  margin: 0!important;
  border-bottom: none!important;
}
.main-opop-modal .ant-list-header .list-header {
  color: #6F7C98;
  margin: 16px;
  font-weight: 400;
}
.main-opop-modal .ant-list-header .list-name {
  background: #F5F6F8;
  color: #6F7C98;
  border: 1px solid #E4E7F2;
  padding: 5px 24px;
  margin-bottom: 0;
}
.main-opop-modal .ant-list-header .list-item {
  color: #3B4168;
  font-size: 16px;
  line-height: 20px;
}
.appoint-new-replication-modal {
  color: #3B4168 !important;
}
.appoint-new-replication-modal h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #3B4168;
  margin-bottom: 0;
}
.appoint-new-replication-modal h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.appoint-new-replication-modal .subtitle {
  color: #6F7C98;
  font-size: 14px;
  margin: 0;
  padding: 0;
}
