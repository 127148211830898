/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
}
.h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}
.h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}
.h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}
.h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}
.h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.buttons {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.main-text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.caption {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.table-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.helptext {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.small {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}
.mobile-h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  font-size: 28px;
  line-height: 36px;
}
.mobile-h3 {
  font-family: 'Montserrat', sans-serif;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}
.mobile-h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}
.ant-modal.work-program-modal .modal-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
  color: #3B4168;
  margin-bottom: 4px;
}
.ant-modal.work-program-modal .modal-subtitle {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: #6F7C98;
}
.ant-modal.work-program-modal .ant-divider {
  margin: 16px 0 24px 0;
}
.ant-modal.work-program-modal .filter-block {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  color: #6F7C98;
  margin-bottom: 4px;
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.ant-modal.work-program-modal .programs-list {
  border: 1px solid #E4E7F2;
}
.ant-modal.work-program-modal .programs-list .list-header {
  padding: 16px 12px;
  border-bottom: 1px solid #E4E7F2;
}
.ant-modal.work-program-modal .programs-list .list-header span {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #6F7C98;
}
.ant-modal.work-program-modal .programs-list .list-header-bordered {
  padding: 16px 12px;
  border-bottom: 1px solid #E4E7F2;
}
.ant-modal.work-program-modal .programs-list .list-item {
  padding: 16px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.22s all;
}
.ant-modal.work-program-modal .programs-list .list-item span {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #3B4168;
}
.ant-modal.work-program-modal .programs-list .list-item .item-actions {
  display: flex;
  align-items: center;
}
.ant-modal.work-program-modal .programs-list .list-item .item-actions a {
  display: grid;
}
.ant-modal.work-program-modal .programs-list .list-item .item-actions svg {
  margin-left: 30px;
  cursor: pointer;
}
.ant-modal.work-program-modal .programs-list .list-item .item-actions svg path {
  fill: #909EBB;
}
.ant-modal.work-program-modal .programs-list .list-item:not(:last-child) {
  border-bottom: 1px solid #E4E7F2;
}
.ant-modal.work-program-modal .programs-list .list-item:hover {
  cursor: pointer;
  background-color: #F5FBFF;
  transition: 0.22s all;
}
.ant-modal.work-program-modal .programs-list .list-item:hover span {
  color: #0071CE;
}
