/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
}
.h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}
.h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}
.h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}
.h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}
.h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.buttons {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.main-text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.caption {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.table-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.helptext {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.small {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}
.mobile-h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  font-size: 28px;
  line-height: 36px;
}
.mobile-h3 {
  font-family: 'Montserrat', sans-serif;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}
.mobile-h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}
.regulatory-documents-modal {
  max-width: 1248px !important;
  width: 100%;
}
.regulatory-documents-modal .ant-modal-content {
  padding: 32px;
}
.regulatory-documents-modal .ant-modal-header {
  padding: 0 0 16px 0;
  border-color: #E4E7F2;
}
.regulatory-documents-modal .ant-modal-header .ant-modal-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
  color: #3B4168;
  margin-bottom: 0px;
}
.regulatory-documents-modal .ant-modal-body {
  padding: 24px 0 24px 0;
}
.regulatory-documents-modal .ant-modal-footer {
  border-color: #E4E7F2;
  padding: 24px 0 0;
  margin-top: 0;
}
.regulatory-documents-modal .ant-modal-footer .ooc-button-v2.ant-btn-link {
  height: auto;
  padding: 0;
}
.regulatory-documents-modal .regulatory-documents-modal__search {
  margin-bottom: 24px;
}
.regulatory-documents-modal .regulatory-documents-modal__search-stats {
  display: flex;
  color: #909EBB;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 4px;
}
.regulatory-documents-modal .regulatory-documents-modal__page-size-control {
  display: inline-flex;
  color: #909EBB;
}
.regulatory-documents-modal .regulatory-documents-modal__page-size-control .ant-select.page-size-select .ant-select-selector {
  padding-left: 4px;
}
.regulatory-documents-modal .regulatory-documents-modal__page-size-control .ant-select.page-size-select .ant-select-selector .ant-select-selection-item {
  font-size: 16px;
  line-height: 20px;
  color: #0071CE;
}
.regulatory-documents-modal .regulatory-documents-modal__page-size-control .ant-select.page-size-select .ant-select-arrow {
  color: #0071CE;
}
.regulatory-documents-modal .regulatory-documents-modal__page-number-control .page-stats {
  font-size: 16px;
  line-height: 20px;
  color: #909EBB;
  margin-right: 16px;
}
.regulatory-documents-modal .regulatory-documents-modal__page-number-control .page-nav .anticon {
  font-size: 15px;
  padding: 0.167em;
  color: #BDBDBD;
  cursor: pointer;
}
.regulatory-documents-modal .regulatory-documents-modal__page-number-control .page-nav .anticon:hover {
  color: #0071CE;
}
.regulatory-documents-modal .regulatory-documents-modal__page-number-control .page-nav .anticon:first-child {
  margin-right: 8px;
}
.regulatory-documents-modal .regulatory-documents-modal__page-number-control .page-nav .anticon:last-child {
  transform: rotate(180deg);
}
.regulatory-documents-modal .regulatory-documents-modal__list.ant-list.ant-list-bordered {
  border-color: #E4E7F2;
}
.regulatory-documents-modal .regulatory-documents-modal__list.ant-list.ant-list-bordered .ant-list-item {
  border-color: #E4E7F2;
}
.regulatory-documents-modal .regulatory-documents-modal__list.ant-list .ant-list-header {
  font-size: 16px;
  line-height: 20px;
  padding: 15px 11px 16px;
  color: #6F7C98;
}
.regulatory-documents-modal .regulatory-documents-modal__list.ant-list .ant-spin-container {
  height: 520px;
  overflow-y: scroll;
}
.regulatory-documents-modal .regulatory-documents-modal__list.ant-list .ant-spin-container .ant-list-items .ant-list-item {
  padding: 0;
}
.regulatory-documents-modal .regulatory-documents-modal__list.ant-list .ant-spin-container .ant-list-items .ant-list-item .doc-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 16px 11px;
  color: #3B4168;
  cursor: pointer;
}
.regulatory-documents-modal .regulatory-documents-modal__list.ant-list .ant-spin-container .ant-list-items .ant-list-item .doc-item .doc-name {
  font-size: 16px;
  line-height: 20px;
  margin-right: 28px;
}
.regulatory-documents-modal .regulatory-documents-modal__list.ant-list .ant-spin-container .ant-list-items .ant-list-item .doc-item .ref-icon {
  line-height: 0;
  color: #909EBB;
}
.regulatory-documents-modal .regulatory-documents-modal__list.ant-list .ant-spin-container .ant-list-items .ant-list-item .doc-item .ref-icon svg {
  width: 20px;
  height: 20px;
  padding: 0.167em;
}
.regulatory-documents-modal .regulatory-documents-modal__list.ant-list .ant-spin-container .ant-list-items .ant-list-item .doc-item:hover,
.regulatory-documents-modal .regulatory-documents-modal__list.ant-list .ant-spin-container .ant-list-items .ant-list-item .doc-item:hover .ref-icon {
  color: #0071CE;
  background-color: #F5FBFF;
}
