/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
}
.h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}
.h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}
.h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}
.h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}
.h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.buttons {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.main-text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.caption {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.table-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.helptext {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.small {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}
.mobile-h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  font-size: 28px;
  line-height: 36px;
}
.mobile-h3 {
  font-family: 'Montserrat', sans-serif;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}
.mobile-h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}
.opop-registry .cell-text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #3B4168;
}
.opop-registry .cell-text p {
  margin: 0;
}
.opop-registry .cell-text.secondary {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: #6F7C98;
}
.opop-registry .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.opop-registry .title .table-title {
  color: #3B4168;
  font-size: 24px;
  line-height: 28px;
}
.opop-registry .title .statistic {
  background: none;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0071CE;
}
.opop-registry .programs-table .header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.opop-registry .programs-table .header .title {
  margin: 0;
  margin-right: 36px;
}
.opop-registry .programs-table .ooc-table .ant-table-thead > tr > th {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #6F7C98;
}
.opop-registry .programs-table .ooc-table .ant-table-thead > tr > th svg {
  width: 14px;
  height: 14px;
  cursor: pointer;
}
.opop-registry .programs-table .ooc-table .ant-table-thead > tr > th svg.ant-popover-open {
  color: #0071CE;
}
.opop-registry .programs-table .ooc-table .ant-table-thead > tr > th svg.active path {
  fill: #0071CE;
}
.opop-registry .programs-table .search {
  max-width: 590px;
  margin-bottom: 24px;
}
.opop-registry .programs-table .search .search-icon {
  width: 16px;
  height: 16px;
}
.opop-registry .programs-table .search .search-icon path {
  fill: #909EBB;
}
.opop-registry .programs-table .programs-list {
  max-width: 851px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.opop-registry .programs-table .programs-list .list-item {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
}
.opop-registry .programs-table .programs-list .list-item:not(:last-child) {
  border-bottom: 1px solid #E4E7F2;
}
.opop-registry .programs-table .programs-list .list-item .item-status {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #3B4168;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}
.opop-registry .programs-table .programs-list .list-item .item-status .circle {
  width: 8px;
  height: 8px;
  border-radius: 16px;
  background: #73AAFF;
  margin-right: 6px;
}
.opop-registry .programs-table .programs-list .list-item .item-status .circle.draft {
  background: #F7B055;
}
.opop-registry .programs-table .programs-list .list-item .item-content {
  display: flex;
  justify-content: space-between;
}
.opop-registry .programs-table .programs-list .list-item .item-content .content-info h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #3B4168;
  margin-bottom: 12px;
}
.opop-registry .programs-table .programs-list .list-item .item-content .content-info h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.opop-registry .programs-table .programs-list .list-item .item-content .content-info p {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #6F7C98;
  margin: 0;
}
.opop-registry .programs-table .programs-list .list-item .item-content .content-actions {
  min-width: 80px;
  width: 80px;
  display: flex;
  justify-content: space-between;
}
.opop-registry .programs-table .programs-list .list-item .item-content .content-actions svg {
  width: 16px;
  cursor: pointer;
}
.opop-registry .programs-table .programs-list .list-item .item-content .content-actions svg:not(:last-child) {
  margin-right: 45px;
}
.opop-registry .programs-table .ant-input-affix-wrapper {
  border-radius: 4px;
  border-color: #E4E7F2;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.modal-header .title {
  font-size: 20px;
}
.modal-header .dwn-btn {
  cursor: pointer;
  font-size: 14px;
}
.modal-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  align-items: center;
  color: #3B4168 !important;
}
.modal-row :first-child {
  text-align: left;
}
.modal-row :last-child {
  text-align: right;
}
.modal-row .count {
  width: 250px;
}
.modal-row .count-item {
  width: 280px;
}
.modal-row p {
  width: 465px;
}
.modal-row .ant-tooltip-open {
  background: red!important;
}
.modal-row .ant-tooltip-open .ant-tooltip-inner {
  color: yellow;
  background-color: green;
}
.modal-row button {
  border: none;
  background: transparent;
  width: 30px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
  position: relative;
}
.modal-row button .tooltiptext {
  visibility: hidden;
  box-sizing: border-box;
  width: 186px;
  height: 50px;
  font-size: 14px;
  line-height: 20px;
  background-color: #fff;
  color: #3B4168;
  text-align: center;
  border-radius: 4px;
  padding: 5px 0;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  /* Position the tooltip */
  position: absolute;
  top: 0;
  left: 23px;
  z-index: 1;
}
.modal-row button:hover .tooltiptext {
  visibility: visible;
}
.modal-row button:hover {
  color: #0071CE;
  background: #E0F1FF;
  border-radius: 4px;
}
.modal-row .dw-icon {
  margin: 0 10px 0 10px;
}
