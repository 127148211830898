/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
}
.h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}
.h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}
.h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}
.h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}
.h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.buttons {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.main-text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.caption {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.table-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.helptext {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.small {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}
.mobile-h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  font-size: 28px;
  line-height: 36px;
}
.mobile-h3 {
  font-family: 'Montserrat', sans-serif;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}
.mobile-h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}
.ooc-dropdown-search {
  min-width: 138px;
}
.ooc-dropdown-search .ant-select {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
}
.ooc-dropdown-search .ant-select.ant-select-focused .ant-select-selector {
  border-color: #F5F6F8 !important;
  -webkit-box-shadow: initial !important;
  box-shadow: initial !important;
}
.ooc-dropdown-search .ant-select:not(.ant-select-customize-input) {
  border-color: initial !important;
}
.ooc-dropdown-search .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #F5F6F8 !important;
}
.ooc-dropdown-search .ant-select-selector {
  border-radius: 8px !important;
}
.ooc-dropdown-search.ant-select-dropdown {
  top: 280px !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.ooc-dropdown-search.nav-dropdown {
  width: fit-content !important;
}
.ooc-dropdown-search.nav-dropdown .ant-select-single {
  box-shadow: none;
}
.ooc-dropdown-search.nav-dropdown .ant-select-single .ant-select-selector {
  padding: initial;
  background: #F5F6F8;
  border-color: #F5F6F8;
}
.ooc-dropdown-search.nav-dropdown .ant-select-single .ant-select-selector .ant-select-selection-item {
  display: flex;
}
.ooc-dropdown-search.nav-dropdown .ant-select-single .ant-select-selector .ant-select-selection-item span {
  font-weight: 600;
  margin-left: 8px;
}
.ooc-dropdown-search.nav-dropdown.ant-select-dropdown {
  top: 16px !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.ooc-dropdown-search.nav-dropdown.ant-select-dropdown .rc-virtual-list-holder-inner > .ant-select-item .nav-item img:not([alt="hat"]):not([alt="monitor"]) {
  margin-left: 4px;
}
