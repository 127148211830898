/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
}
.h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}
.h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}
.h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}
.h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}
.h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.buttons {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.main-text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.caption {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.table-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.helptext {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.small {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}
.mobile-h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  font-size: 28px;
  line-height: 36px;
}
.mobile-h3 {
  font-family: 'Montserrat', sans-serif;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}
.mobile-h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}
.details-info .info-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.details-info .ant-btn.back-button svg {
  transform: rotate(180deg);
}
.details-info .info-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
  color: #3B4168;
}
.details-info .info-subtitle {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
  color: #3B4168;
  margin-bottom: 24px;
}
.details-info .info-panel {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.details-info .info-panel .label {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #6F7C98;
  margin-bottom: 4px;
}
.details-info .info-panel .text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #3B4168;
  margin-bottom: 16px;
}
.details-info .info-panel .panel-row {
  display: flex;
}
.details-info .info-panel .panel-row div:not(:first-child) {
  margin-left: 32px;
}
.details-info .info-panel .info-bg {
  position: relative;
}
.details-info .info-panel .info-bg img {
  position: absolute;
  height: 288px;
  width: 276px;
  left: 50%;
  transform: translateX(-50%);
  top: -75px;
}
.details-info .opop-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}
.details-info .opop-content .opop-content-item {
  padding: 16px;
  background-color: white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  transition: 0.22s;
  display: flex;
}
.details-info .opop-content .opop-content-item .item-icon {
  margin-right: 14px;
}
.details-info .opop-content .opop-content-item .download-dock-btn {
  display: flex;
  align-items: center;
}
.details-info .opop-content .opop-content-item .download-dock-btn .download-dock-btn-text {
  color: #005EAC;
}
.details-info .opop-content .opop-content-item .download-dock-btn svg {
  width: 15px;
  height: 17px;
  margin-right: 10px;
  fill: #909EBB;
}
.details-info .opop-content .opop-content-item .item-content .content-header {
  margin-bottom: 16px;
}
.details-info .opop-content .opop-content-item .item-content .content-header h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  color: #3B4168;
}
.details-info .opop-content .opop-content-item .item-content .content-header h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.details-info .opop-content .opop-content-item .item-content .content-footer {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #6F7C98;
}
.details-info .opop-content .opop-content-item:hover {
  background-color: #F5FBFF;
  cursor: pointer;
  transition: 0.22s;
}
.details-info .opop-content .opop-content-item:hover .item-content .content-header h5 {
  color: #0071CE;
}
