/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
}
.h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}
.h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}
.h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}
.h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}
.h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.buttons {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.main-text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.caption {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.table-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.helptext {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.small {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}
.mobile-h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  font-size: 28px;
  line-height: 36px;
}
.mobile-h3 {
  font-family: 'Montserrat', sans-serif;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}
.mobile-h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}
.spinner {
  display: flex;
  justify-content: center;
  align-items: center ;
  height: 100vh ;
}
.commons-nav-mobile-menu {
  z-index: 10001 !important;
}
.page-wrapper {
  height: 100%;
  background: #F5F6F8;
  flex-direction: column;
  display: flex;
  min-height: 100vh;
}
.page-wrapper .ant-tabs.page-nav .ant-tabs-nav-wrap {
  justify-content: center;
}
.page-wrapper__container {
  min-height: calc(100% - (64px + 55px + 60px));
  display: flex;
  position: relative;
}
.page-wrapper__content {
  width: 100%;
  padding: 40px;
  overflow: auto;
  position: relative;
  padding-left: calc(60px + 40px);
  transition: all 0.3s, outline 0s;
}
.page-wrapper__content.nav-drawer-opened {
  transition: all 0.3s, outline 0s;
  padding-left: calc(256px + 40px);
}
@media screen and (max-width: 1366px) {
  .page-wrapper__content.nav-drawer-opened {
    padding-left: calc(256px + 16px);
  }
}
.page-wrapper__content::-webkit-scrollbar {
  width: 8px;
}
.page-wrapper__content::-webkit-scrollbar-thumb {
  border-left: 4px solid #DAE4EE;
  border-radius: 4px;
}
.page-wrapper__content::-webkit-scrollbar-thumb:hover {
  border-left: 6px solid #DAE4EE;
}
@media screen and (max-width: 1366px) {
  .page-wrapper__content {
    padding: 16px 16px 16px calc(60px + 16px);
  }
}
@media screen and (max-width: 756px) {
  .page-wrapper__content {
    padding: 16px;
  }
}
.page-wrapper .mobile-nav-drawer {
  position: absolute;
  transition: all 0.3s, outline 0s;
}
.page-wrapper .mobile-nav-drawer .ant-drawer-body {
  padding: 0;
}
.page-wrapper .mobile-nav-drawer.small {
  min-width: 60px !important;
  width: 60px !important;
  transition: all 0.3s, outline 0s;
}
.page-wrapper .mobile-nav-drawer.ant-drawer-open .ant-drawer-mask {
  background: rgba(228, 231, 242, 0.4);
  backdrop-filter: blur(10px);
}
@media screen and (max-width: 756px) {
  .page-wrapper__content > div {
    padding-left: 0px;
    transition: all 0.3s, outline 0s;
  }
}
