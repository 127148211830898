/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
}
.h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}
.h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}
.h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}
.h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}
.h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.buttons {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.main-text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.caption {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.table-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.helptext {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.small {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}
.mobile-h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  font-size: 28px;
  line-height: 36px;
}
.mobile-h3 {
  font-family: 'Montserrat', sans-serif;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}
.mobile-h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}
.ooc-pagination-v2 {
  font-family: 'IBM Plex sans', sans-serif;
  min-width: fit-content;
  display: flex;
  align-items: center;
}
.ooc-pagination-v2__left-total,
.ooc-pagination-v2 .ant-pagination {
  color: #6F7C98;
}
.ooc-pagination-v2__left-total {
  margin: 6px 0 8px;
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.ooc-pagination-v2__left-total--hidden > span {
  display: none;
}
.ooc-pagination-v2__clear-button {
  margin-left: 20px;
}
.ooc-pagination-v2 .ant-pagination {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.ooc-pagination-v2 .ant-pagination::before {
  order: -2;
  content: attr(data-options-label);
}
.ooc-pagination-v2 .ant-pagination-item {
  display: none !important;
}
.ooc-pagination-v2 .ant-pagination-options {
  order: -1;
}
.ooc-pagination-v2 .ant-pagination-options .ant-select-selector {
  border-radius: 10px !important;
  height: 34px !important;
  display: flex;
  align-items: center;
  border: none !important;
  box-shadow: none !important;
}
.ooc-pagination-v2 .ant-pagination-options .ant-select-dropdown {
  padding: 0;
  border-radius: 8px;
}
.ooc-pagination-v2 .ant-pagination-options .ant-select-dropdown .ant-select-item-option {
  padding-left: 16px;
}
.ooc-pagination-v2 .ant-pagination-options .ant-select-dropdown .ant-select-item-option-active {
  background-color: #0071CE10;
}
.ooc-pagination-v2 .ant-pagination-options .ant-select-dropdown .ant-select-item-option-selected {
  background-color: initial;
  color: #0071CE;
}
.ooc-pagination-v2 .ant-pagination-total-text {
  margin-left: 64px;
}
.ooc-pagination-v2 .ant-pagination-prev[aria-disabled="true"] button,
.ooc-pagination-v2 .ant-pagination-next[aria-disabled="true"] button {
  color: #12152A;
}
.ooc-pagination-v2 .ant-pagination-prev[aria-disabled="false"] button,
.ooc-pagination-v2 .ant-pagination-next[aria-disabled="false"] button {
  color: #0071CE;
}
.ooc-pagination-v2 .ant-pagination-jump-next,
.ooc-pagination-v2 .ant-pagination-jump-prev {
  display: none;
}
