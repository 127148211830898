/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
}
.h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}
.h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}
.h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}
.h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}
.h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.buttons {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.main-text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.caption {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.table-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.helptext {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.small {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}
.mobile-h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  font-size: 28px;
  line-height: 36px;
}
.mobile-h3 {
  font-family: 'Montserrat', sans-serif;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}
.mobile-h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}
.ooc-text-editor.quill {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  border: none;
}
.ooc-text-editor .ql-container {
  height: calc(100% - 34px);
  order: 1;
  border: 1px solid #E4E7F2;
  border-top: 1px solid #E4E7F2 !important;
  border-radius: 4px 4px 0 0;
}
.ooc-text-editor .ql-container .ql-editor {
  min-height: 200px;
}
.ooc-text-editor .ql-container .ql-editor.ql-blank::before {
  color: #bfbfbf;
  font-style: normal;
  font-size: 16px;
}
.ooc-text-editor .ql-container .ql-editor {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  font-style: normal !important;
  font-weight: 400;
}
.ooc-text-editor .ql-container ::-webkit-input-placeholder {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  color: #909EBB;
}
.ooc-text-editor .ql-container :-moz-placeholder {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  color: #909EBB;
}
.ooc-text-editor .ql-container ::-moz-placeholder {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  color: #909EBB;
}
.ooc-text-editor .ql-container :-ms-input-placeholder {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  color: #909EBB;
}
.ooc-text-editor .ql-toolbar {
  order: 2;
  padding: 4px 10px;
  background: #F5F5F7;
  border: 1px solid #E4E7F2;
  border-radius: 0px 0px 4px 4px;
}
