/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
}
.h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}
.h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}
.h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}
.h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}
.h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.buttons {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.main-text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.caption {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.table-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.helptext {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.small {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}
.mobile-h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  font-size: 28px;
  line-height: 36px;
}
.mobile-h3 {
  font-family: 'Montserrat', sans-serif;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}
.mobile-h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}
.nav-side-menu {
  height: 100%;
  max-width: 300px;
  min-width: 60px;
  font-family: 'IBM Plex sans', sans-serif;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.nav-side-menu .ant-menu-root .ant-menu-submenu > .ant-menu > .ant-menu-item.page-selected {
  position: relative;
  background: linear-gradient(90deg, #0071CE 0%, rgba(0, 113, 206, 0.46) 100%);
}
.nav-side-menu .ant-menu-root .ant-menu-submenu > .ant-menu > .ant-menu-item.page-selected:after {
  clip-path: polygon(100% 0, 6px 50%, 100% 100%);
  background-color: white;
  position: absolute;
  content: "";
  background: white;
  right: 0;
  top: 0;
  width: 12px;
  height: 100%;
  z-index: 3;
  opacity: 1;
  transform: none;
}
.nav-side-menu .menu-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #12152A;
}
.nav-side-menu .menu-footer .contact-list {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
}
.nav-side-menu .menu-footer .contact-list .list-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.nav-side-menu .menu-footer .contact-list .list-item:not(:last-child) {
  margin-bottom: 16px;
}
.nav-side-menu .menu-footer .contact-list .list-item span {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: #909EBB;
}
.nav-side-menu .menu-footer .contact-list .list-item svg {
  min-width: 16px;
  height: 16px;
  margin-right: 8px;
}
.nav-side-menu .menu-footer .contact-list .list-item svg path {
  fill: #909EBB;
}
.nav-side-menu .menu-footer .ant-divider {
  margin: 0;
  padding: 0;
  background-color: #909EBB;
}
.nav-side-menu .menu-footer .footer-toggle {
  margin: 16px;
  align-self: flex-end;
  cursor: pointer;
  transition: 0.22s all;
}
.nav-side-menu .menu-footer .footer-toggle.opened {
  transition: 0.33s;
  transform: rotate(180deg);
}
.nav-side-menu .ant-menu {
  height: 100%;
  user-select: none;
  background: #12152A;
}
.nav-side-menu .ant-menu.opened > .ant-menu-item .item-link span {
  display: none;
}
.nav-side-menu .ant-menu-root {
  overflow-y: auto;
  overflow-x: hidden;
}
.nav-side-menu .ant-menu-root > .ant-menu-item {
  padding-left: 16px !important;
  padding-right: 0 !important;
}
.nav-side-menu .ant-menu-root > .ant-menu-item .item-link {
  display: flex;
  align-items: center;
}
.nav-side-menu .ant-menu-root > .ant-menu-item .item-link svg {
  margin-right: 12px;
  width: 20px;
  height: 20px;
}
.nav-side-menu .ant-menu-root > .ant-menu-item .item-link svg path {
  fill: white;
}
.nav-side-menu .ant-menu-root > .ant-menu-item.page-selected {
  background: linear-gradient(90deg, #0071CE 0%, rgba(0, 113, 206, 0.46) 100%);
  position: relative;
}
.nav-side-menu .ant-menu-root > .ant-menu-item.page-selected:after {
  clip-path: polygon(100% 0, 6px 50%, 100% 100%);
  background-color: white;
  position: absolute;
  content: "";
  background: white;
  right: 0;
  top: 0;
  width: 12px;
  height: 100%;
  content: '';
  z-index: 3;
  opacity: 1;
  transform: none;
}
.nav-side-menu .ant-menu-root > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 16px !important;
  padding-right: 0 !important;
}
.nav-side-menu .ant-menu-root::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.nav-side-menu .ant-menu-root .ant-menu-submenu > .ant-menu > .ant-menu-item {
  margin: 8px 0;
}
.nav-side-menu .ant-menu-dark.ant-menu-root,
.nav-side-menu .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #12152A;
}
.nav-side-menu .ant-menu-item,
.nav-side-menu .ant-menu-item a,
.nav-side-menu .ant-menu-item span a {
  color: white;
}
.nav-side-menu .ant-menu-item:first-child {
  margin: 24px 0;
}
.nav-side-menu .ant-menu-item > a {
  flex: auto !important;
  white-space: normal;
  line-height: 20px;
}
.nav-side-menu .ant-menu-item-selected:not(.nav-side-menu__submenu-items--page-selected) {
  background-color: unset !important;
}
.nav-side-menu .ant-menu-item-icon {
  width: 20px;
  height: fit-content;
  fill: white;
}
