/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
}
.h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}
.h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}
.h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}
.h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}
.h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.buttons {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.main-text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.caption {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.table-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.helptext {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.small {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}
.mobile-h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  font-size: 28px;
  line-height: 36px;
}
.mobile-h3 {
  font-family: 'Montserrat', sans-serif;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}
.mobile-h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}
.calendar-schedule-tab__upload-button.ant-btn.ant-btn-link {
  color: #1781a9;
  margin-top: 17px;
}
.calendar-schedule-tab__upload-button.ant-btn.ant-btn-link span,
.calendar-schedule-tab__upload-button.ant-btn.ant-btn-link svg {
  vertical-align: middle;
}
.calendar-schedule-tab__upload-item {
  display: flex;
  flex-direction: column-reverse;
}
.calendar-schedule-tab__upload-item .ant-upload-select {
  text-align: right;
}
.calendar-schedule-tab__upload-item .ant-upload-select .disabled-btn {
  color: #BDBDBD;
  cursor: not-allowed;
}
.calendar-schedule-tab .constructor-form .ant-upload {
  display: flex;
  justify-content: end;
}
.calendar-schedule-tab .constructor-form .uploaded-list {
  width: 100%;
  margin-top: 20px;
}
.calendar-schedule-tab .constructor-form .uploaded-list .list-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.calendar-schedule-tab .constructor-form .uploaded-list .list-item .file-name {
  display: flex;
  align-items: center;
  width: 100%;
  color: #1781a9;
  font-size: 16px;
}
.calendar-schedule-tab .constructor-form .uploaded-list .list-item .file-name .file-icon {
  margin-right: 10px;
}
.calendar-schedule-tab .constructor-form .uploaded-list .list-item .delete-icon {
  color: #1781a9;
  cursor: pointer;
}
.calendar-schedule-tab .constructor-form .form-next-btn {
  margin-left: auto;
}
