.download-body {
  text-align: center;
  .download-notice {
    font-size: 16px;
    line-height: 20px;
    color: #3B4168FF;
    margin: 20px 0;
  }
  .download-sub {
    color: #6F7C98;
    font-size: 14px;
    line-height: 16px;
    margin-top: 10px;
  }
  .download-text {
    font-size: 16px;
    color: #3B4168FF;;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 55%;
    span {
      color: #0071CE;
    }
    &.danger {
      color: #EB5757;
    }
  }
  .file-list {
    display: flex;
    width: 600px;
    justify-content: space-between;
   .file-list-item {
     .file {
       width: 15px;
       margin-right: 5px;
       margin-bottom: -7px;
       color: #0071CE;
     }
   }

  }
}
.ant-upload.ant-upload-drag {
  &.danger {
    background: #FFF7F7 !important;
    border: 2px dashed #F39A9A !important;
  }
  &.limit {
    background: #FFF7F7 !important;
    border: 2px dashed #BDBDBD !important;
    color: #BDBDBD !important;
  }
}

