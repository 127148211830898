// @import "../core.less";

.h1 {
    // font-family: @font-header;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;   /* bold */
    font-style: normal;
    font-size: 32px;
    line-height: 40px;
}

.h2 {
    // font-family: @font-header;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 28px;
    line-height: 36px;
}

.h3 {
    // font-family: @font-header;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;   /* semi-bold */
    font-style: normal;
    font-size: 24px;
    line-height: 28px;
}

.h4 {
    // font-family: @font-header;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
}

.h5 {
    // font-family: @font-header;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;   /* medium */
    font-style: normal;
    font-size: 18px;
    line-height: 24px;

    &.footer-title {
        font-family: 'IBM Plex sans', sans-serif;
        font-weight: 400; 
        font-size: 16px;
        line-height: 20px;
    }
}

.buttons {
    // font-family: @font-content;
    font-family: 'IBM Plex sans', sans-serif;
    font-weight: 500;   /* medium */
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
}

.main-text {
    // font-family: @font-content;
    font-family: 'IBM Plex sans', sans-serif;
    font-weight: 400;   /* regular */
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
}

.caption {
    // font-family: @font-content;
    font-family: 'IBM Plex sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
}

.table-header {
    // font-family: @font-header;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;   /* semi-bold */
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
}

.helptext {
    // font-family: @font-content;
    font-family: 'IBM Plex sans', sans-serif;
    font-weight: 400;   /* regular */
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
}

.small {
    // font-family: @font-header;
    font-family: 'IBM Plex sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
}

.mobile-h1 {
    .h1();
    font-size: 28px;
    line-height: 36px;
}

.mobile-h3 {
    .h3();
    font-size: 22px;
    line-height: 28px;
    font-weight: 600;
}

.mobile-h4 {
    .h4();
    font-weight: 500;
}
