/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
}
.h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}
.h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}
.h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}
.h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}
.h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.buttons {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.main-text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.caption {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.table-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.helptext {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.small {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}
.mobile-h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  font-size: 28px;
  line-height: 36px;
}
.mobile-h3 {
  font-family: 'Montserrat', sans-serif;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}
.mobile-h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}
.dev-educational-program .scroll-container {
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  float: right;
  align-items: center;
  width: 90px;
  background-color: transparent;
  top: 116px;
  height: 70vh;
  z-index: 1000;
}
.dev-educational-program .scroll-container [ant-click-animating-without-extra-node='true']::after {
  display: none;
}
.dev-educational-program .scroll-container .scroll-btn {
  border: none;
  color: #909EBB;
  font-size: 16px;
  box-shadow: none;
  touch-action: none;
  width: 100%;
  background-color: transparent;
  opacity: 0;
}
.dev-educational-program .scroll-container .scroll-btn:hover {
  color: #0071CE;
}
.dev-educational-program .scroll-container .ant-btn-dashed:hover,
.dev-educational-program .scroll-container .ant-btn-dashed:focus {
  background-color: transparent;
  border: none;
}
.dev-educational-program .scroll-container .on-top,
.dev-educational-program .scroll-container .on-bottom {
  opacity: 1;
}
.dev-educational-program .program-title {
  margin-bottom: 4px;
  color: #3B4168;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}
.dev-educational-program .program-title-name {
  color: #909EBB;
  line-height: 16px;
  font-weight: 400;
  flex: none;
  order: 0;
  flex-grow: 1;
  margin-bottom: 24px;
}
.dev-educational-program .space {
  margin: 20px 0;
}
.dev-educational-program .active {
  grid-template-columns: 313px 1fr;
}
.dev-educational-program .collapsed {
  grid-template-columns: 80px 1fr;
}
.dev-educational-program .program-content {
  display: grid;
  column-gap: 48px;
}
.dev-educational-program .program-content .tabs-container {
  transition: all 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.dev-educational-program .program-content .program-menu {
  position: relative;
  padding-bottom: 60px;
}
.dev-educational-program .program-content .program-menu .menu-footer {
  padding-right: 22px;
  border-top: 1px solid #E4E7F2;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.dev-educational-program .program-content .program-menu .menu-footer svg {
  cursor: pointer;
}
.dev-educational-program .program-content .program-menu .menu-footer .arrow-open {
  transform: rotate(180deg);
}
.dev-educational-program .program-content .program-menu .menu-footer .arrow-closed {
  transform: rotate(0deg);
}
.dev-educational-program .program-content .program-menu .menu-footer-closed {
  justify-content: center;
  padding-right: 0;
}
.dev-educational-program .ant-tabs-top > .ant-tabs-nav {
  display: none;
}
.dev-educational-program .return-btn-open {
  padding: 0 !important;
  padding-left: 17px !important;
  height: 76px !important;
}
.dev-educational-program .return-btn {
  height: 76px !important;
  display: flex;
  align-items: center;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.dev-educational-program .my-opop {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #0071CE;
}
.dev-educational-program .my-opop-text {
  margin-left: 15px;
}
.dev-educational-program .ant-menu-sub.ant-menu-inline {
  background: transparent;
}
.dev-educational-program .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1;
  font-family: 'IBM Plex sans', sans-serif;
  content: "*";
}
.dev-educational-program .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
  content: none;
}
.dev-educational-program .ant-menu {
  background-color: #F5F6F8;
  border-right: none;
}
.dev-educational-program .ant-menu.ant-menu-inline .ant-menu-submenu {
  padding-left: 0;
  background: transparent;
}
.dev-educational-program .ant-menu.ant-menu-inline .ant-menu-submenu.ant-menu-submenu-open {
  position: relative;
}
.dev-educational-program .ant-menu.ant-menu-inline .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-arrow {
  transform: rotate(180deg);
  color: white;
}
.dev-educational-program .ant-menu.ant-menu-inline .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title {
  background: #0071CE;
  color: white;
}
.dev-educational-program .ant-menu.ant-menu-inline .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title::after {
  content: "";
  position: absolute;
  top: 0;
  width: 6px;
  height: 100%;
  right: -1px;
  clip-path: polygon(100% 0, 0% 50%, 100% 100%);
  background-color: white;
}
.dev-educational-program .ant-menu.ant-menu-inline .ant-menu-submenu .ant-menu-submenu-arrow {
  left: 18px;
  right: 0;
  transform: rotate(-90deg);
  color: #BDBDBD;
}
.dev-educational-program .ant-menu.ant-menu-inline .ant-menu-submenu .ant-menu-submenu-arrow:hover {
  color: #BDBDBD;
}
.dev-educational-program .ant-menu.ant-menu-inline .ant-menu-submenu-title {
  background-color: transparent;
  padding-left: 48px !important;
  margin: 0;
  height: 36px;
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.dev-educational-program .ant-menu.ant-menu-inline .ant-menu-submenu-title.disabled {
  color: #BDBDBD;
}
.dev-educational-program .ant-menu.ant-menu-inline .ant-menu-submenu-title:focus {
  background-color: transparent;
}
.dev-educational-program .ant-menu.ant-menu-inline .ant-menu-submenu-title:hover {
  color: #005EAC;
}
.dev-educational-program .ant-menu.ant-menu-inline .ant-menu-item {
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  border: none;
  color: #6F7C98;
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.dev-educational-program .ant-menu.ant-menu-inline .ant-menu-item .ant-menu-title-content {
  text-overflow: unset;
  white-space: normal;
}
.dev-educational-program .ant-menu.ant-menu-inline .ant-menu-item:focus {
  border: none;
  background-color: transparent;
}
.dev-educational-program .ant-menu.ant-menu-inline .ant-menu-item:hover {
  color: #005EAC;
}
.dev-educational-program .ant-menu.ant-menu-inline .ant-menu-item.checked::after {
  background-image: url("../../../../assets/icons/check.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  width: 48px;
  transform: scaleY(1);
  opacity: 1;
  top: 0;
  left: 0;
}
.dev-educational-program .ant-menu.ant-menu-inline .ant-menu-item.ant-menu-item-selected {
  border: none;
  background-color: transparent;
}
.dev-educational-program .ant-menu.ant-menu-inline .ant-menu-item.ant-menu-item-selected .ant-menu-submenu-arrow {
  transform: rotate(180deg);
}
.dev-educational-program .ant-menu.ant-menu-inline .ant-menu-item.ant-menu-item-selected::after {
  background-image: url("../../../../assets/icons/dot.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  width: 48px;
  top: 0;
  left: 0;
}
.dev-educational-program .ant-menu-inline-collapsed {
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;
}
.dev-educational-program .ant-menu-inline-collapsed .ant-menu-submenu {
  padding-left: 0;
  background: transparent;
}
.dev-educational-program .ant-menu-inline-collapsed .ant-menu-submenu-title {
  background-color: transparent;
  margin: 0;
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.dev-educational-program .ant-menu-inline-collapsed .ant-menu-submenu-title.disabled {
  color: #BDBDBD;
}
.dev-educational-program .ant-menu-inline-collapsed .ant-menu-submenu-title:focus {
  background-color: transparent;
}
.dev-educational-program .ant-menu-inline-collapsed .ant-menu-submenu-title:hover {
  color: #005EAC;
}
.dev-educational-program .ant-menu-inline-collapsed .ant-menu-submenu-title::after {
  content: "";
  position: absolute;
  top: 0;
  width: 9px;
  height: 100%;
  right: -1px;
  clip-path: polygon(100% 0, 0% 50%, 100% 100%);
  background-color: #F5F6F8;
}
.dev-educational-program .ant-menu-inline-collapsed .ant-menu-submenu-selected {
  background: #0071CE;
  color: white;
}
.dev-educational-program .ant-menu-inline-collapsed .ant-menu-submenu-selected:hover {
  color: white !important;
}
.dev-educational-program .constructor-form {
  width: 100%;
  max-width: 885px;
}
.dev-educational-program .constructor-form-tight {
  max-width: 692px;
}
.dev-educational-program .constructor-form .ant-form-item {
  line-height: 16px;
  margin-bottom: 0;
}
.dev-educational-program .constructor-form .ant-form-item-control-input {
  min-height: 20px;
}
.dev-educational-program .constructor-form section > .ant-row:not(:last-child),
.dev-educational-program .constructor-form section .form-item:not(:last-child) {
  margin-bottom: 24px;
}
.dev-educational-program .constructor-form section .form-items-list {
  margin-bottom: 24px;
}
.dev-educational-program .constructor-form section .form-items-list .form-items-group:not(:last-child) {
  margin-bottom: 24px;
}
.dev-educational-program .constructor-form section > .subtitle {
  margin-bottom: 24px;
}
.dev-educational-program .constructor-form section .ant-divider-horizontal {
  margin-top: 24px;
  margin-bottom: 24px;
}
.dev-educational-program .constructor-form .ant-divider-horizontal {
  margin-top: 40px;
  margin-bottom: 40px;
}
.dev-educational-program .constructor-form .item-block-group {
  padding: 15px;
  border: 1px solid #E4E7F2;
  border-radius: 4px;
}
.dev-educational-program .constructor-form .item-block-group .ant-row:not(:last-child) {
  margin-bottom: 24px;
}
.dev-educational-program .constructor-form .item-block-group-horizontal .ant-row:not(:last-child) {
  margin-bottom: 16px;
}
.dev-educational-program .constructor-form .item-block-group-2 {
  padding: 15px;
  border: 1px solid #E4E7F2;
  border-radius: 4px;
}
.dev-educational-program .constructor-form .item-block-group-3:not(:last-child) {
  margin-bottom: 24px;
}
.dev-educational-program .constructor-form .item-block-group-3 .form-items-list {
  margin-bottom: 4px;
}
.dev-educational-program .constructor-form .item-block-group-3 .form-items-list .form-items-group:not(:last-child) {
  margin-bottom: 8px;
}
.dev-educational-program .constructor-form .item-block-group-4 {
  padding: 15px;
  border: 1px solid #E4E7F2;
  border-radius: 4px;
}
.dev-educational-program .constructor-form .item-block-group-4 .form-items-list {
  margin-bottom: 4px;
}
.dev-educational-program .constructor-form .item-block-group-4 .form-items-list .form-items-group:not(:last-child) {
  margin-bottom: 8px;
}
.dev-educational-program .constructor-form .ant-form-item-label {
  padding-bottom: 0;
  line-height: 1;
}
.dev-educational-program .constructor-form .ant-form-item-label label {
  color: #6F7C98;
  padding-bottom: 4px;
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.dev-educational-program .constructor-form .item-block-group-indent {
  margin: 0;
}
.dev-educational-program .constructor-form .item-label {
  display: block;
  color: #6F7C98;
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.dev-educational-program .constructor-form .item-label:not(.ant-form-item-required) {
  margin-bottom: 4px;
}
.dev-educational-program .constructor-form .item-label-big {
  font-size: 16px;
  margin-bottom: 0;
}
.dev-educational-program .constructor-form .item-action {
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.dev-educational-program .constructor-form .title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #3B4168;
  margin-bottom: 0px;
}
.dev-educational-program .constructor-form .title.m-0 {
  margin: 0;
}
.dev-educational-program .constructor-form .text {
  font-size: 16px;
  line-height: 20px;
  color: #3B4168;
}
.dev-educational-program .constructor-form .subtext {
  font-size: 14px;
  line-height: 16px;
  color: #909EBB;
}
.dev-educational-program .constructor-form .m-20 {
  margin: 20px 0;
}
.dev-educational-program .constructor-form .subtitle {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #3B4168;
  margin-bottom: 0;
}
.dev-educational-program .constructor-form .subtitle.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.dev-educational-program .constructor-form .subtitle.subtitle-with-desc {
  margin-bottom: 8px;
}
.dev-educational-program .constructor-form .section-desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 24px;
}
.dev-educational-program .constructor-form .confirm-subtitle {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #3B4168;
  margin-bottom: 8px;
}
.dev-educational-program .constructor-form .confirm-subtitle.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.dev-educational-program .constructor-form .items-group-title {
  font-family: "IBM Plex sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 8px;
}
.dev-educational-program .constructor-form .block-header {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.dev-educational-program .constructor-form .block-main-text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.dev-educational-program .constructor-form .ant-space-item {
  width: 100%;
}
.dev-educational-program .constructor-form .ant-radio {
  top: 0.15em;
}
.dev-educational-program .constructor-form .ant-radio-wrapper {
  margin-top: 8px;
  margin-right: 24.5px;
  font-size: 16px;
  line-height: 1.25;
}
.dev-educational-program .constructor-form .ant-radio-wrapper:hover .ant-radio-inner {
  border-color: #0071CE;
}
.dev-educational-program .constructor-form .ant-radio-inner {
  border-color: #E4E7F2;
}
.dev-educational-program .constructor-form .ant-radio-inner::after {
  background-color: #0071CE;
}
.dev-educational-program .constructor-form .ant-radio-checked .ant-radio-inner::after {
  transform: scale(0.75);
}
.dev-educational-program .constructor-form .form-select .ant-select-selector {
  padding-right: 15px !important;
  padding-left: 15px !important;
  border: 1px solid #e4e7f2;
  border-radius: 4px;
}
.dev-educational-program .constructor-form .form-select .ant-select-selector .ant-select-selection-search {
  right: 33px;
  left: 15px;
}
.dev-educational-program .constructor-form .form-select .ant-select-arrow {
  right: 20px;
}
.dev-educational-program .constructor-form .label.disabled {
  color: #BDBDBD;
}
.dev-educational-program .constructor-form .prompt-message {
  color: #6F7C98;
}
.ant-notification.ant-notification-bottomRight {
  margin-right: 52px;
}
.ant-notification.ant-notification-bottomRight .dev-educational-program.ant-notification-notice {
  width: 735px;
  border-radius: 4px;
}
.ant-notification.ant-notification-bottomRight .dev-educational-program.ant-notification-notice .ant-notification-notice-content .ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
}
.ant-notification.ant-notification-bottomRight .dev-educational-program.ant-notification-notice .ant-notification-notice-content .ant-notification-notice-with-icon .ant-notification-notice-icon {
  color: #FFFFFF;
}
.ant-notification.ant-notification-bottomRight .dev-educational-program.ant-notification-notice .ant-notification-notice-content .ant-notification-notice-with-icon .ant-notification-notice-message {
  display: none;
}
.ant-notification.ant-notification-bottomRight .dev-educational-program.ant-notification-notice .ant-notification-notice-content .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-right: 22px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: white;
}
.ant-notification.ant-notification-bottomRight .dev-educational-program.ant-notification-notice .ant-notification-notice-content .ant-notification-notice-with-icon .ant-notification-notice-description.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.ant-notification.ant-notification-bottomRight .dev-educational-program.ant-notification-notice .ant-notification-notice-close {
  color: #FFFFFF;
}
.ant-notification.ant-notification-bottomRight .dev-educational-program.ant-notification-notice .ant-notification-notice-close:hover {
  color: #E26C3C;
}
.ant-notification.ant-notification-bottomRight .dev-educational-program.ant-notification-notice.ant-notification-notice-warning {
  background: linear-gradient(90deg, #faa05a 0%, #fd9a1b 100%);
}
