/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
}
.h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}
.h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}
.h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}
.h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}
.h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.buttons {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.main-text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.caption {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.table-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.helptext {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.small {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}
.mobile-h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  font-size: 28px;
  line-height: 36px;
}
.mobile-h3 {
  font-family: 'Montserrat', sans-serif;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}
.mobile-h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}
.ooc-table .ant-table {
  border-radius: 4px;
  border: 1px solid #f0f0f0;
}
.ooc-table .ant-table-container {
  overflow-y: auto;
}
.ooc-table .ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 16px;
}
.ooc-table .ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 16px;
}
.ooc-table .ant-table-thead > tr > th {
  background-color: #FFFFFF;
  color: #6F7C98;
}
.ooc-table .ant-table-thead > tr > th::before {
  content: none !important;
}
.ooc-table .ant-table-column-sorters {
  justify-content: start;
}
.ooc-table .ant-table-column-sorters .ant-table-column-sorter {
  margin-left: 8px;
}
.ooc-table .ant-table-content .ant-table-tbody .ant-table-row {
  font-family: 'IBM Plex sans', sans-serif;
  line-height: 20px;
  font-size: 16px;
  cursor: pointer;
}
.ooc-table .ant-table-content .ant-table-tbody .ant-table-cell {
  font-family: 'IBM Plex sans', sans-serif;
  line-height: 20px;
  font-size: 16px;
}
.ooc-table .ant-table-content .ant-table-tbody .ant-table-row-expand-icon-cell,
.ooc-table .ant-table-content .ant-table-tbody .ant-table-row-expand-icon-cell ~ .ant-table-cell {
  border-bottom: none;
  padding-bottom: 0;
}
.ooc-table .ant-table-content .ant-table-tbody .ant-table-expanded-row .ant-table-cell {
  font-family: 'IBM Plex sans', sans-serif;
  padding: 4px 16px 16px 64px;
  background-color: white;
  color: #6F7C98;
  line-height: 16px;
  font-size: 14px;
}
.ooc-table .ant-table-pagination {
  margin-right: 48px;
}
.ooc-table .ant-table-pagination .ant-pagination-item {
  display: none!important;
}
.ooc-table .ant-table-pagination .ant-pagination-jump-next {
  display: none!important;
}
.ooc-table .ant-table-pagination .ant-pagination-jump-prev {
  display: none!important;
}
.ooc-table .ant-table-pagination .ant-select-selection-item {
  min-width: 67px;
}
.ooc-table .ant-table-column-sorter-inner .ant-table-column-sorter-up.active,
.ooc-table .ant-table-column-sorter-inner .ant-table-column-sorter-down.active {
  display: inline-block;
  color: #12152A;
}
