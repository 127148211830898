/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
}
.h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}
.h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}
.h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}
.h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}
.h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.buttons {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.main-text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.caption {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.table-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.helptext {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.small {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}
.mobile-h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  font-size: 28px;
  line-height: 36px;
}
.mobile-h3 {
  font-family: 'Montserrat', sans-serif;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}
.mobile-h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}
.ooc-heatmap-description {
  width: 90%;
  margin: 0 auto;
  position: relative;
}
.ooc-heatmap-description__title {
  text-align: center;
}
.ooc-heatmap-description__body {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}
.ooc-heatmap-description__marker {
  display: flex;
  align-items: center;
}
.ooc-heatmap-description__marker::before {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 8px;
}
.ooc-heatmap-description__marker:nth-child(1)::before {
  background: rgba(18, 21, 42, 0.2);
}
.ooc-heatmap-description__marker:nth-child(2)::before {
  background: #EB5757;
}
.ooc-heatmap-description__marker:nth-child(3)::before {
  background: #F7B055;
}
.ooc-heatmap-description__marker:nth-child(4)::before {
  background: #B9E8B8;
}
.ooc-heatmap-description__marker:nth-child(5)::before {
  background: #15B012;
}
.ooc-heatmap-description__marker > .anticon {
  margin-left: 8px;
  font-size: 10px;
  color: #D0D0D4;
}
.ooc-heatmap-description__tooltip {
  display: none;
  position: absolute;
  bottom: 83px;
  left: 0;
  width: 300px;
  background: #0071CE;
  color: #FFFFFF;
  padding: 8px 16px;
  border-radius: 4px;
}
.ooc-heatmap-description__tooltip span {
  display: inline-block;
  font-size: 14px;
  opacity: 0.7;
  text-align: center;
}
