/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
}
.h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}
.h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}
.h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}
.h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}
.h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.buttons {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.main-text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.caption {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.table-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.helptext {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.small {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}
.mobile-h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  font-size: 28px;
  line-height: 36px;
}
.mobile-h3 {
  font-family: 'Montserrat', sans-serif;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}
.mobile-h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}
.ant-modal.main-developer-modal .search-input {
  margin-bottom: 24px;
}
.ant-modal.main-developer-modal .modal-header .title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
  color: #3B4168;
  margin-bottom: 4px;
}
.ant-modal.main-developer-modal .modal-header .description {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: #6F7C98;
}
.ant-modal.main-developer-modal .modal-header .description::first-letter {
  text-transform: capitalize;
}
.ant-modal.main-developer-modal .user-list {
  border: 1px solid #E4E7F2;
  border-radius: 2px;
}
.ant-modal.main-developer-modal .user-list .list-header {
  padding: 16px 12px;
}
.ant-modal.main-developer-modal .user-list .list-header span {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-modal.main-developer-modal .user-list .list-item {
  border-top: 1px solid #E4E7F2;
  padding: 16px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ant-modal.main-developer-modal .user-list .list-item.group {
  background-color: #F5F6F8;
  padding: 8px 12px;
}
.ant-modal.main-developer-modal .user-list .list-item.group span {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-modal.main-developer-modal .user-list .list-item span {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #3B4168;
}
.ant-modal.main-developer-modal .current-page {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 8px;
  text-align: center;
  margin-top: 24px;
}
.ant-modal.main-developer-modal .slider-nav-arrow {
  cursor: pointer;
}
.ant-modal.main-developer-modal .ant-modal-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
  color: #3B4168;
}
.ant-modal.main-developer-modal .react-pdf__Page__canvas,
.ant-modal.main-developer-modal .react-pdf__Document {
  max-width: 1255px;
  max-height: 705px;
  width: 100% !important;
  height: 100% !important;
}
.ant-modal.main-developer-modal .react-pdf__Page__canvas {
  border: 1px solid #E4E7F2;
  border-radius: 10px;
}
.ant-modal.main-developer-modal .react-pdf__Document {
  margin: 0 12px;
}
.ant-modal.main-developer-modal .react-pdf__Page__textContent {
  width: 100% !important;
  height: 100% !important;
}
.ant-modal.main-developer-modal .viewer-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 705px;
}
@media screen and (max-width: 1366px) {
  .ant-modal.main-developer-modal .viewer-wrapper {
    min-height: auto;
  }
}
.ant-modal.main-developer-modal .loading-overlay {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.4);
}
