.common-info-block {
  display: flex;
  .common-info-sider {
    height: 748px;
    width: 208px;
    background: #E4E7F2;
    padding: 32px;
    border-radius: 10px;
    margin-right: 40px;
  }
  .info-viewer {
    height: 748px;
    overflow-y: scroll;
    overflow-x: unset;
    &::-webkit-scrollbar {
      display: none;
    }
    .info {
      width: 100%;
    }
    .info-viewer-helpers {
     display: flex;
      .info-viewer-icons {
        display: flex;
        flex-direction: column;
        margin: 0 20px;
      }
    }
  }
}

