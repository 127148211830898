/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
}
.h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}
.h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}
.h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}
.h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}
.h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.buttons {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.main-text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.caption {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.table-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.helptext {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.small {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}
.mobile-h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  font-size: 28px;
  line-height: 36px;
}
.mobile-h3 {
  font-family: 'Montserrat', sans-serif;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}
.mobile-h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}
.expert__codeAndName,
.expert__sender--name,
.expert__organizationName--title {
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3B4168;
}
.expert__codeAndName:hover,
.expert__sender--name:hover,
.expert__organizationName--title:hover {
  color: #0071CE;
}
.page-title-box {
  align-items: baseline;
}
.page-title-box .edit-role {
  color: #0071CE;
  font-weight: 500;
  display: flex;
  cursor: pointer;
}
.search-opop {
  width: 590px;
  margin-top: 14px;
  margin-bottom: 26px;
}
.expert__sender--date,
.expert__organizationName--info {
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #6F7C98;
}
.expert-status {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.expert__status--icon {
  width: 30px;
  height: 30px;
}
.expert__status--iconGreen {
  width: 8px;
  height: 8px;
}
.expert__status--process {
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #3B4168;
}
.expert__status--confirm {
  cursor: pointer;
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0071CE;
}
.search {
  margin-bottom: 20px;
}
.search-icon {
  width: 16px;
  height: 16px;
}
.search-icon path {
  fill: #909EBB;
}
.plus-icon {
  cursor: pointer;
}
.modal {
  font-family: 'IBM Plex Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.modal-title {
  font-size: 14px;
  color: #6F7C98;
}
.modal-info {
  font-size: 16px;
  color: #3B4168;
}
.show-edit-icon {
  cursor: pointer;
}
.expert-edit {
  color: #6F7C98;
  display: flex;
}
.not-found {
  color: #6F7C98;
  display: flex;
  align-items: center;
  font-size: 16px;
}
.info-label {
  color: #6F7C98;
  margin: 0;
}
.ant-radio-group.radio {
  display: flex !important;
  flex-direction: column;
}
