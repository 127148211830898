.program-header {
  display: flex;
  justify-content: space-between;
  .program-description{
    .program-title{
      margin: 0;
      padding: 0;
    }
    p {
      font-size: 14px;
      line-height: 16px;
      color: #909EBB;
      margin-bottom: 24px;
    }
  }
}

