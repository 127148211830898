/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
}
.h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 36px;
}
.h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
}
.h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
}
.h5 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}
.h5.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.buttons {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.main-text {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}
.caption {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.table-header {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.helptext {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  /* regular */
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
}
.small {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}
.mobile-h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  /* bold */
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
  font-size: 28px;
  line-height: 36px;
}
.mobile-h3 {
  font-family: 'Montserrat', sans-serif;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}
.mobile-h4 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
}
.work-saved-practices-tab {
  max-width: 1016px;
}
.work-saved-practices-tab__header {
  display: flex;
  margin-bottom: 20px;
}
.work-saved-practices-tab__header .ooc-button-v2 {
  margin-left: 36px;
  z-index: 1040;
}
.work-saved-practices-tab__title-modal {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  /* semi-bold */
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
  color: #3B4168;
}
.work-saved-practices-tab__title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  color: #3B4168;
}
.work-saved-practices-tab__subtitle {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  /* medium */
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: #3B4168;
  margin-top: 24px;
}
.work-saved-practices-tab__subtitle.footer-title {
  font-family: 'IBM Plex sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.work-saved-practices-tab__search-block {
  margin-bottom: 24px;
}
.work-saved-practices-tab__search-block svg path {
  fill: #909EBB;
}
.work-saved-practices-tab__programs-list {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  flex-flow: row wrap;
}
.work-saved-practices-tab__programs-list:after {
  content: "";
  flex: 1 1 100%;
  max-width: 23%;
}
.work-saved-practices-tab__program-item {
  padding: 8px;
  flex: 1 1 100%;
  cursor: pointer;
  max-width: 23%;
  min-height: 104px;
  min-width: 230px;
  border-radius: 6px;
  border: 1px solid #E4E7F2;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.work-saved-practices-tab__program-item:hover {
  color: #0071CE;
  background: #F5FBFF;
  box-shadow: 0px 4px 15px rgba(33, 36, 55, 0.12);
}
.work-saved-practices-tab__program-list-item {
  padding: 16px;
  flex: 1 1 100%;
  cursor: pointer;
  max-width: 100%;
  min-height: 84px;
  min-width: 230px;
  border-radius: 10px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.work-saved-practices-tab__program-list-item:hover {
  background: #F5FBFF;
  box-shadow: 0px 4px 15px rgba(33, 36, 55, 0.12);
}
.work-saved-practices-tab__program-list-item:hover .work-saved-practices-tab__item-title {
  color: #0071CE;
}
.work-saved-practices-tab__item-title {
  color: #3B4168;
  height: 75%;
  font-size: 16px;
}
.work-saved-practices-tab__item-status {
  display: flex;
  align-items: baseline;
  height: 25%;
}
.work-saved-practices-tab__item-status .circle {
  width: 8px;
  height: 8px;
  border-radius: 16px;
  background: #73AAFF;
  margin-right: 6px;
}
.work-saved-practices-tab__item-status .circle.draft {
  background: #F7B055;
}
.work-saved-practices-tab .item-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.work-saved-practices-tab__confirm-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #3b4168;
  padding-bottom: 20px;
}
.work-saved-practices-tab .trash-btn {
  cursor: pointer;
}
.work-saved-practices-tab__delete-modal {
  max-width: 700px;
}
.work-saved-practices-tab .ant-divider-horizontal {
  margin-top: 8px;
  margin-bottom: 8px;
}
