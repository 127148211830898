.message {
  padding: 2px 0;
  display: flex;
  align-items: flex-start;

  .icon {
    margin-right: 8.17px;
    margin-top: 4px;
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6F7C98;
    white-space: pre-wrap;
  }
}
