.event-item {
    width: 382px;
    overflow: hidden;
    text-overflow: ellipsis; /* TODO: заменить цсс элипсис на жс и обрабатывать в конвертере */

    &__header {
        font-size: 16px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
    }

    &__type {
        text-transform: uppercase;
    }

    &__title {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 8px;
    }

    &__body {
        font-size: 14px;
        font-weight: 400;
        word-break: break-word;
        height: 48px;
        width: 382px;
        overflow: hidden;
        text-overflow: ellipsis; /* TODO: заменить цсс элипсис на жс и обрабатывать в конвертере */
    }
}
